@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Gotham';
    font-weight: normal;
    font-display: swap;
    src: url('./Gotham\ Book\ Font.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 500;
    font-display: swap;
    src: url('./Gotham\ Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 600;
    font-display: swap;
    src: url('./Gotham\ Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 700;
    font-display: swap;
    src: url('./Gotham\ Bold.otf') format('opentype');
}


.material-symbols-outlined {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24,
}