// primary colors
$primary-active-color: rgb(224, 71, 91);
$primary-button-color: rgb(224, 71, 91);

$primary: rgb(224, 71, 91);
$seconday: #2C3A56;


.primary {
    color: $primary-active-color !important;
}