.btn {
  font-size: 14px;
}

.btn-default {
  background-color: #EBEFF6 !important;
  border-color: #EBEFF6 !important;
}

.btn-primary {
  background-color: $primary-coffee-light !important;
  border-color: $primary-button-color !important;
}

.btn-danger {
  background-color: $primary-coffee-light !important;

}

.btn-outline-dark {
  background: #fff;
  color: $primary-coffee-light;
  border-color: $primary-coffee-light !important;
}

.btn-light-yellow {
  background-color: $primary-yellow;
  color: $primary-coffee-light;
  font-weight: 700;
}

.ant-pagination-item,
.pagination-item-link,
.pagination-next {
  background-color: $primary-yellow !important;
  border: none !important;
}

.pagination-next {
  font-weight: 400;
}

.ant-pagination-item-active {
  background-color: $primary-coffee!important;
  border: none !important;
  color: #fff !important;
}

.ant-pagination {
  .ant-select-selector {
    background-color: $primary-yellow !important;

    .ant-select-selector {
      background-color: $primary-coffee-light !important;
    }
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: $primary-yellow !important;
}



.page-limit {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $primary-yellow !important;
    // padding:.5em;
    border: none !important
  }
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector :focus {
  outline: none !important;
}