.ant-form-item {
    label {
        font-weight: 500;
        font-size: 15px;
    }

    .ant-input,
    .ant-select .ant-select-selector,
    .ant-picker,
    .ant-input-number-input {
        border: 1px solid #ced4da !important;
        border-radius: 5px !important;
        font-size: 13.5px !important;
        width: 100%;

        &:hover,
        &:focus {
            border: 1px solid #ced4da !important;
            box-shadow: none !important;
        }
    }

    .ant-input-number-handler-wrap {
        display: none !important;
    }

    .ant-input-group>.ant-input:first-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .ant-input-lg,
    .ant-picker-large {
        padding: 8.5px 11px;
    }

    .ant-form-item-explain {
        font-size: 12px !important;
        display: block;
        margin-top: 5px !important;
        color: #2C3A56 !important;

        &:hovers {
            color: inherit !important;
        }

        &.ant-form-item-explain-error {
            color: #ff4d4f !important;
        }
    }

    &.ant-form-item-has-error .ant-input {
        border: 1px solid #ff4d4f !important;
    }
}

label {
    font-weight: 500;
    font-size: 15px;
}

.ant-notification {
    z-index: 99999991 !important;
}

.loading-modal {
    .ant-modal-confirm-btns {
        display: none !important;
    }

    .ant-modal-confirm-content {
        margin-top: 0px !important;
    }
}

.ant-tabs-tab-active {
    font-weight: bold !important;
}

.ant-checkbox-group-item {
    display: block !important;

    .ant-checkbox-inner {
        display: inline-block !important;
    }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-range {
    height: 36px !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 24px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-picker-range {
    line-height: 36px !important;
}

.ant-picker-large .ant-picker-input>input,
.ant-input-lg,
.ant-select-lg {
    font-size: 13.5px !important;
}