.modal-content {
  border-radius: 1.3rem !important;
}

.background {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.form-group {
  margin-bottom: 22px;

  label {
    font-weight: 500;
    font-size: 15px;
  }

  .form-control {
    font-size: 13.5px !important;
    padding: 10px 11px;
    height: auto;
    border-radius: 5px;

    &:focus {
      outline: 0 !important;
      box-shadow: none;
      border: 1px solid #ced4da;
    }
  }
}

.custom-dropdown {
  width: 100%;
  background: white !important;
  color: grey;
  border: 1px solid #ced4da !important;
}

.custom-dropdown {
  color: #000 !important;
}

.custom-dropdown:focus {
  color: #000 !important;
}

.custom-dropdown:hover {
  color: #000 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
}

.button-size {
  width: 140px;
  height: 54px;
}

.fs-12 {
  font-size: 12px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-smaller {
  font-size: smaller;
}

.fw-600 {
  font-weight: 600;
}

.b1-dash {
  border: 1px dashed;
}

.cr-p {
  cursor: pointer;
}

.grey-color {
  color: #989ca4;
}

.grey-link {
  color: #989ca4;
}

.grey-link:hover {
  color: #2c3a56;
}

.a-link {
  color: #2c3a56;
}

.a-link:hover {
  color: white;
}

.image-div {
  position: relative;
}

.image-div:hover .overlay {
  opacity: 0.9;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: #B6101D !important;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white !important;
  font-size: 14px;
  padding: 20px;
  text-align: center;
}

.color-link {
  color: #2c3a56;
  text-decoration: none !important;
}

.ht-54 {
  height: 54px;
}

.ht-320 {
  height: 320px;
}

.ht-700 {
  height: 700px;
}

.htv-100 {
  height: 100vh;
}

.mt-15 {
  margin-top: 15%;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.border1-white {
  border: 1px solid #fff;
}

.logo-img1 {
  background: #fff;
  height: 120px;
  width: 120px;
}

.login-button {
  border-radius: 30px;
  width: 140px;
  height: 54px;
  border: 0.5px solid black;
}

.blur {
  padding-top: 48px;
}

.ml-64 {
  margin-left: 82px;
  font-size: 24px;
  margin-top: -58px;
}

.b1-white {
  border: 1px solid #fff;
}

.br-5 {
  border-radius: 5px;
}

.br-12 {
  border-radius: 12px;
}

.br-15 {
  border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
}

.br-30 {
  border-radius: 30px;
}

.card-style {
  border-radius: 15px;
  width: fit-content;
  margin: auto;
}

.w-58 {
  max-width: 58%;
}

.backdrop {
  backdrop-filter: brightness(20%);
  width: fit-content;
}

.btn-backdrop {
  backdrop-filter: brightness(0.5) !important;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: $primary-coffee-light;
  // border-color: #2c3a56;
}

.ellipse {
  overflow: inherit;
  max-width: 75%;
  text-overflow: inherit;
}

.menu-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-style {
  z-index: 9;
  background-color: #fff !important;
  border-bottom-color: $primary-yellow !important;
}

.notification {
  span {
    color: #B6101D;
    background-color: #F9B200;
    padding: .3em;
    border-radius: 50px;
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: -10px;
    left: -10px;
    cursor: pointer;
  }
}

.message-style {
  color: #cc3300;
  margin-top: 2px;
  font-size: 11px;
}

.blocked {
  cursor: not-allowed;
  pointer-events: none;
}