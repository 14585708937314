@import url('../fonts/_fonts.css');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  background-color: #fff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 600 !important;
}

// p {
//   font-weight: 400;
//   font-family: 'Roboto', sans-serif !important;
//   font-size: 16px;
//   margin-top: 0px;
// }

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.wd-100p {
  width: 100% !important;
}

small {
  font-size: 12px;
}

.hover:hover {
  background-color: #f6f6f6 !important;
}

.primary {
  color: #B6101D !important;
}

.progress-bar.bg-primary {
  background-color: #B6101D !important;
}

input[type="checkbox"]:after {
  background-color: #B6101D !important;
}

.min-height {
  height: 100vh;
  min-height: 600px;
}

$dark: #2c3a56;
$danger: #B6101D;

$primary-coffee: #B6101D;
$primary-yellow: #FDC400;
$primary-coffee-light: #B6101D;
$primary-yellow-dark: #FDC400;
$primary-yellow-light: #FFF5CC;
$primary-yellow-very-light: rgba(255, 245, 204, 0.5);


// Bootstrap Override
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";


@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1600px;
    max-width: 94vw;
    padding: 0;
  }
}

.divider {
  border-bottom: 1px solid #FFF5CC !important;
}

.hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.bg-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 20vh;
}

.dropdown {
  .dropdown-toggle {
    padding: 0;

    &::after {
      display: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    // width: 50vw;
    border: 0;
    padding: 0;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
    border-radius: 7px;

    a {
      font-size: 12px;
      border-bottom: 1px solid #EBEFF6;
      padding: 0.8rem 1rem;

      &:first-child {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      &:last-child {
        border: 0;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }
}

.table {

  thead {
    background: rgba(248, 249, 253, 1);

    th {
      border-bottom: 0px !important;
    }
  }

  tbody {
    tr:hover {
      background: rgba(248, 249, 253, 1);
    }
  }
}

#OrdersCard {
  &:hover {
    background-color: rgba($primary-yellow, .1);
  }

  &.active-order {
    border: 2px solid $primary !important;
    background-color: rgba($primary-yellow, .1);
  }
}

.card-head {
  background-color: #EBEFF6;
}

.divider {
  border-bottom: 1px solid #dee2e6;
}

#Subscription {
  .plan {
    border: 2px solid rgba($primary, .3);
    border-radius: 5px;
    cursor: pointer;

    &:hover,
    &.active {
      border: 2px solid rgba($primary, 1);
    }
  }
}

#NotificationAudio {
  z-index: 9999999;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($seconday, .7);

  >div {
    width: 25vw;
    padding: 40px 20px;
    text-align: center;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 1px 1px 15px 0px rgba($seconday, .7);
  }
}

#Radio {

  &.radio>div,
  &.radio>div>div {
    border-radius: 50%;
  }

  &.checkbox>div,
  &.checkbox>div>div {
    border-radius: 3px;
  }

  &.primary>div {
    border: 2px solid $primary;
    height: 25px;
    width: 25px;
    background-color: #fff;
  }

  &.primary>div>div {
    border: 2px solid $primary;
    height: 15px;
    width: 15px;
    background-color: $primary;
  }

  &.secondary>div {
    border: 2px solid $secondary;
    height: 25px;
    width: 25px;
    background-color: #fff;
  }

  &.secondary>div>div {
    border: 2px solid $secondary;
    height: 16px;
    width: 16px;
    background-color: $secondary;
  }
}

.grayscale {
  filter: grayscale(1);
  opacity: .6;
}

.report-link {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 60px 0;
  cursor: pointer;
  transition: 0.5s ease;

  i,
  .material-symbols-outlined {
    color: $primary-coffee;
    font-size: 46px;
  }

  &:hover {
    background-color: rgba($primary, .2);
  }
}

.jumbotron {
  padding: 1rem 1rem !important;
  border-radius: 8px !important;
  background-color: rgba($primary, .05) !important;
}

#Upload {
  border: 1px dashed #ccc;
  // padding: 40px 10px;
  border-radius: 6px;
  height: 30vh;
  position: relative;
  background-color: #fff;

  .uploader {
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 3px;
    min-height: 10vh;
  }

  .rem {
    background-color: $primary;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    margin: 5px 0 0 5px;
    font-weight: bold;
    cursor: pointer;
  }
}