.nav-link {
  font-weight: 400;
  color: #fff;
}

.active-nav-text {
  color: $primary-active-color !important;
}

.register-button {
  border: 1px solid rgb(44, 58, 86);
  border-radius: 20px;
}

.register-button.active-nav-text {
  border-color: $primary-active-color !important;
}


.sidebar-sticky {
  background-color: $primary-coffee-light ;

  li {
    border-bottom: 1px solid rgba(255, 245, 204, 0.6) !important;
    padding: 2em 0em;
  }

  .logout {
    border-bottom: none !important;
    padding: 2em 0em;
  }

  .btn-contact-admin {
    border-color: $primary-yellow;
    background-color: $primary-coffee;
    font-weight: 700;
    line-height: 24px;
    width: 70%;
    color: $primary-yellow;
  }

  .contact-admin {
    display: flex;
    justify-content: center;
    margin-top: 250px;
    color: $primary-yellow;

  }
}

.nav-link-dashboard {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.tabs {
  background-color: $primary-yellow;
  border: none;

  .tab {
    float: left;
    padding: 15px 25px;
    // border-bottom: 4px solid #fff;
    cursor: pointer;
    background-color: $primary-yellow;

    &.active {
      border-bottom: 2px solid $primary-coffee;
    }
  }
}