@import "./_variables";
@import "./_base";
@import "./_buttons";
@import "./navbar";
@import "./home.scss";
@import "./grids.scss";
@import "./dashboard.scss";
@import "./sizing.scss";
@import "./register.scss";
@import "./_antd";

* {
  *outline: 1px solid rgba($primary, .4) !important;
}

.form-text {
  margin-top: 0px !important;
}

td {
  vertical-align: middle !important;
}

.addonBefore .ant-input-wrapper .ant-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.bottom-10 {
  margin-bottom: 5rem !important;
}

.text-dark {
  color: #1B1C1E !important;
}