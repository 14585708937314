.dashboard-grid-wrapper {
  display: grid;
  grid-template-columns: 27% 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "dashboard-header dashboard-header"
    "dashboard-left-sidebar dashboard-main"
    "dashboard-left-sidebar dashboard-main";
  height: 100vh;
  overflow: hidden;
}

.dashboard-grid-header {
  grid-area: dashboard-header;
  box-sizing: border-box;
}

.dashboard-left-sidebar {
  grid-area: dashboard-left-sidebar;
  box-sizing: border-box;
  // background: white;
  z-index: 999;
}

.dashboard-grid-main {
  grid-area: dashboard-main;
  box-sizing: border-box;
  overflow-y: auto;
}

.sidebar-stickys,
.dashboard-left-sidebar {
  // border-top: 10px solid #e0475c;
  background-color: $primary-coffee-light;
  height: 87.1vh;
  width: 240px;
  position: fixed;
  border-top-left-radius: 40px !important;
}

.dashboard-grid-main {
  margin-left: 265px;
  width: 100vw;
}

.dashboard-grid-main::-webkit-scrollbar {
  display: none;
}

.dashboard-grid-main {
  // -ms-overflow-style: none;
  // /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}