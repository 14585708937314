.drawer-link {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff !important;
  margin-left: 30px;
  //opacity: 50%;
  text-decoration: none;
}

.drawer-link:hover {
  font-size: 16px;
  // line-height: 16px;
  color: #fff;
  // margin-left: 30px;
  //opacity: 50%;
  text-decoration: none;
}

.drawer-link-active {
  font-size: 16px;
  font-weight: 700;
  // line-height: 16px; 
  color: #fff !important;
  // margin-left: 30px;
  //opacity: 100%;
}

.alert-time-zone {
  background-color: $primary-yellow-very-light !important;
}

.dashboard-detail-page-img {
  height: 100px;
  width: 100px;
}

.logout-icon {
  color: #fff;
}

.product-images {
  .image {
    width: 160px;
    height: 140px;
    border-radius: 5px;

    &.upload {
      border: 1px dashed #E0475B;
    }
  }
}

.border-primary-yellow {
  border-color: $primary-yellow;
}

.border-primary-light-yellow {
  border-color: $primary-yellow;
}

.app-logo {
  position: relative;
  left: -40px;
  bottom: -1;
  top: -80px;
  /* right: 30px; */
}


.btn-order-success {
  background-color: #F9B200;
  color: $primary-coffee;
}

.btn-order-green {
  background-color: #14a740;
  color: #fff;

  &:hover {
    background-color: #086123;
    color: #fff;
  }
}

.custom-tab {

  .ant-tabs-nav,
  .ant-tabs-content-holder {
    border: 1px solid $primary-yellow-dark !important;
    padding: 0.8em;
    background-color: #fff;
    border-radius: 0.25rem;
  }

  .ant-tabs-content-holder {
    padding: 1em;
  }

  .ant-tabs-content {
    padding: 1em;
  }

  .border {
    border-color: $primary-yellow !important;
  }

  .profile-title {
    margin-bottom: 0px;
    border-bottom: none !important;
    padding: 1em;
    width: 50%;
  }

  .profile-picture {
    margin-top: 10px;
  }

  .profile-form {
    border-top: none !important;
  }
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
  width: 0px !important;
}

.comment-body {
  font-size: 14px;
  border-radius: 4px;
  font-weight: 300;
  height: 150px !important;
  // overflow-y: scroll;
}

.customer-complaints {
  .comment-body {
    background: #FFCCCC;
    border: 1px solid #B6101D;
  }
}

.customer-reviews {
  .comment-body {
    border: 1px solid #F9B200;
    background-color: #FFF5CC;
  }

  img {
    margin-left: 5px;
  }
}

.settings {
  input:disabled {
    opacity: 1 !important;
    color: #B2BEB5 !important
  }

  ::placeholder {
    color: #B2BEB5 !important
  }
}

.profile-form-container {
  width: 50%;
}

.profile-picture {
  width: 30%;
}

.settings-title {
  font-size: 26px;
  font-weight: 500;
  padding: 0em 0em 0.5em 0.5em;
}

.collabotor-body {
  border: 1px solid $primary-yellow-dark !important;
  margin-top: 20px;
}

.table-td-center {
  vertical-align: middle !important;
}

#hamburger {
  display: none;
}

#hamburger-menu {
  width: 25vw;
  position: fixed;
  z-index: 99999;
  height: 100dvh;
  padding-top: 20px;
}

@media (max-width: 1200px) {

  html,
  body {
    font-size: 12px !important;
  }

  .dashboard-left-sidebar {
    display: none;
  }

  .dashboard-grid-main {
    margin-left: 0px;
  }

  .container {
    max-width: 1020px;
  }

  #hamburger {
    display: block;
  }
}